import {IonButton, IonSkeletonText, useIonModal, useIonRouter} from "@ionic/react";
import {useCallback, useEffect, useState} from "react";
import {Helmet} from 'react-helmet';
import {getProduct} from "../../Api/api.products";
import Product from "./ProductClass";
import PrivatePage from "../../Components/Page/PrivatePage";
import AppHeader from "../../Components/Header/AppHeader";
import ProductHero from "./Components/ProductHero";
import VendorModal from "../Vendor/VendorModal";
import {setVendor} from "../Vendor/VendorStore";
import {UserStore} from "../User/UserStore"
import {useStoreState} from "pullstate";
import {suggestionSearch} from "../../Api/api.search";
import ProductList from "./ProductList";
import UseToggle from "../../Helpers/Hooks/UseToggle";
import {clearActiveCollection} from "../Collection/CollectionStore";


const ProductPage = ({...props}: any) => {
  const userState = useStoreState(UserStore);
  const [hasMounted, setHasMounted] = useState(false);
  const productSlug = props.match.params.slug;
  const urlQuery = new URLSearchParams(new URL(window.location.href).search);
  const productId = urlQuery.get('id');
  const messageId = urlQuery.get('messageId');
  const [product, setProduct] = useState<Product | undefined>(undefined);
  const pathName = window.location.pathname || null;
  const router = useIonRouter();
  const searchParams = window.location.search || null;
  const [suggestProducts, setSuggestProducts] = useState(false);
  const resultsBase: Array<Product> = [];
  const [results, setResults] = useState(resultsBase);
  const [showUnavailable, setShowUnavailable] = UseToggle(false);


  const [presentVendorModal, dismissVendorModal] = useIonModal(VendorModal, {
    onDismiss: () => {
      dismissVendorModal();
    }, router

  });
  const handleViewVendor = () => {
    if (product && product.vendor) {
      setVendor(product.vendor)
      presentVendorModal();
    }
  }
  const handleViewHubDetails = async (id: string) => {

    router.push(`/hubs/${id}`);
  };

  const findHubs = async () => {

    router.push(`/hubs/`);
  };


  const awaitGetProduct = useCallback(async () => {
    let pId = productId;

    if (pId === null) {
      pId = productSlug;
    }

    await getProduct(pId, messageId).then((res: any) => {
      if (res !== 404) {
        res = res as Product;
        setProduct(res);
        if (res?.variants.length === 0) {
          setSuggestProducts(true);
        }
      } else {
        setShowUnavailable(true);
        if (userState.hubs && userState.hubs?.length > 0) {
          setSuggestProducts(true);
        } else {
          setTimeout(() => {
            router.push('/404');
          }, 500);
        }


      }
    });

    // DO NOT ADD ROUTER TO THIS DEPENCENCY.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMounted, productSlug, userState.hubs]);

  useEffect(() => {
    setHasMounted(false);
    setProduct(undefined);
    clearActiveCollection();
  }, [productSlug]);

  useEffect(() => {
    if (!hasMounted) {
      awaitGetProduct().then();
      setHasMounted(true);
    }


  }, [awaitGetProduct, hasMounted, productSlug]);

  useEffect(() => {
    if (userState.hubs && userState.hubs?.length > 0 && suggestProducts) {
      let pId = productId;

      if (pId === null) {
        pId = productSlug;
      }
      suggestionSearch(pId, 1, 18).then((searchResults: any) => {
        if (searchResults) {
          setResults(searchResults.results[0]);
          setSuggestProducts(false);
        }
      })
    }

  }, [hasMounted, product?.variants.length, productId, productSlug, suggestProducts, userState.hubs]);


  return (
    <PrivatePage>
      <Helmet>
        <title>Browse {hasMounted && product !== undefined ? `${product?.title}` : 'Product'} Details</title>
        <link rel="canonical"
              href={`https://shop.marketwagon.com${pathName !== null ? pathName : ''}${searchParams !== null ? searchParams : ''}`}/>
      </Helmet>

      <AppHeader
        specialTab={product?.title}
      />

      {!showUnavailable && <ProductHero
        isLoading={hasMounted}
        compact={props.compact}
        product={product}
        handleViewVendor={handleViewVendor}
        userState={userState}
        handleViewHubDetails={handleViewHubDetails}
      />}
      {(showUnavailable || product?.variants.length === 0) &&
        <section
          aria-label='Product Details'
          className='px-4 py-4 mw-container'
        >
          {showUnavailable && <h2 className='text-red-500 font-bold text-xl'>
            This product is not currently available in your hub.
          </h2>}

          {results.length > 1 &&
            <h2 className='text-red-500 font-bold text-xl'>
              Check out out these other products:
            </h2>
          }
          {!results && showUnavailable &&
            <div>
              <h2 className='text-red-500 font-bold text-xl'>
                To find more local Products. Find a shop to shop in.
              </h2>
              <IonButton size='small' onClick={findHubs}>
                Find Hubs
              </IonButton>
            </div>

          }
        </section>
      }

      <ProductList
        products={results}
        isLoading={!hasMounted}

      />
      <section
        className='md:p-10 grid grid-cols-1 gap-3 px-2 md:p= md:grid-cols-2 md:gap-6 lg:grid-cols-2 xl:grid-cols-3'>
        {suggestProducts &&
          [1, 2, 3, 4, 5, 6].map((i) => {
            return (
              <div key={i} className='bg-white rounded-lg shadow-md'>
                <IonSkeletonText animated style={{height: '120px'}}/>
                <div className='p-4'>
                  <IonSkeletonText
                    animated
                    style={{height: '20px', width: '86%'}}
                  />
                  <IonSkeletonText
                    animated
                    style={{width: '54%', height: '10px'}}
                  />
                </div>
                <div className='flex p-2 space-x-2'>
                  <IonSkeletonText style={{height: '40px'}}/>
                  <IonSkeletonText style={{height: '40px'}}/>
                </div>
              </div>
            );
          })}

      </section>

    </PrivatePage>
  )
}

export default ProductPage;


