import {IonSkeletonText, useIonModal, useIonRouter} from '@ionic/react';
import {useCallback, useEffect, useState} from 'react';
import {getFeed} from '../../Api/api.feed';
import {UserStore} from '../User/UserStore';
import {useStoreState} from 'pullstate';
import {PostCardType} from '../Post/types';
import Seo from '../../Components/SEO/Seo';
import SubscriptionModal from '../Subscriptions/SubscriptionsModal';
import ProfileModal from '../User/ProfileModal';
import ProductTile from "../../NewDomains/Product/Components/ProductTile";

const HomeFeed = () => {
  const router = useIonRouter();
  const userState = useStoreState(UserStore);
  const feedBaseState: Array<PostCardType> = [];
  const [feedItems, setFeedItems] = useState(feedBaseState);
  const [loading, setLoading] = useState(false);
  const [presentSubscriptionModal, dismissSubscriptionModal] = useIonModal(SubscriptionModal, {
    onDismiss: () => {
      dismissSubscriptionModal();

      if (router.routeInfo.pathname.includes('/subscription/')) {
        router.push('/shop');
      }
    }
  });
  const [presentProfileModal, dismissProfileModal] = useIonModal(ProfileModal, {
    onDismiss: () => {
      dismissProfileModal();

      if (router.routeInfo.pathname.includes('/profile/')) {
        router.push('/shop');
      }
    }
  });

  const awaitGetFeed = useCallback(async () => {
    if (userState && userState.hubs?.length) {
      await getFeed(userState.hubs[0].id).then((feedItems: any) => {
        setFeedItems(feedItems);
        setLoading(false);
      });
    }
  }, [userState]);

  /**
   * Load the Feed if
   * 1. Feed Items length is 0
   * 2. User has Hubs
   */
  useEffect(() => {
    setLoading(true);
    awaitGetFeed().then();

    if ((router.routeInfo.pathname === '/account' || router.routeInfo.pathname === '/profile') && !loading) {
      presentProfileModal();
    } else if (router.routeInfo.pathname === '/subscription' && !loading) {
      presentSubscriptionModal();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [awaitGetFeed, presentSubscriptionModal, presentProfileModal]);

  return (
    <div className='mx-auto feed'>
      <Seo title='Market Wagon Feed'/>
      <section
        className='max-w-screen-2xl p-4 mx-auto'
        aria-label='Wagon BundlesSection'
      >
        {/* {!props.hideComposer && <Composer />} */}
        <div
          className='md:p-10 grid grid-cols-1 gap-3 px-2 md:grid-cols-2 md:gap-6 lg:grid-cols-2 xl:grid-cols-3'>
          {/* Skeleton loaders for feed cards */}
          {loading &&
            [1, 2, 3, 4, 5, 6].map((i) => {
              return (
                <div
                  key={i}
                  className='feed-card flex flex-col bg-white rounded-lg shadow-lg overflow-hidden relative'
                >
                  <div className='flex items-center justify-between px-2 pt-2'>
                    <div className='flex flex-row items-center w-full space-x-2 avatar-title-sub'>
                      <IonSkeletonText
                        animated
                        className='inline-flex rounded-full p-1 flex-grow-0 flex-shrink-0 overflow-hidden h-10 w-10'
                      />
                      <div>
                        <IonSkeletonText
                          animated
                          style={{height: '16px', width: '136px'}}
                        />
                        <IonSkeletonText animated className='p-0 m-0 text-xs'/>
                      </div>
                    </div>
                  </div>

                  <div className='px-2 py-2 md:px-4 md:py-4'>
                    <IonSkeletonText
                      animated
                      style={{width: '215px', height: '27px'}}
                      className='flex-grow flex-shrink text-xl rounded-none'
                    />
                    <IonSkeletonText
                      animated
                      className='mt-4 w-full z-10 h-48 rounded-lg md:h-80'
                    />
                  </div>

                  <div className='px-4 pb-2 flex items-end flex-grow-0 flex-shrink-0'>
                    <div className='w-full'>
                      <IonSkeletonText
                        animated
                        style={{width: '50px', height: '19px'}}
                      />
                      <IonSkeletonText animated className='pt-2 w-full h-12'/>
                    </div>
                  </div>
                </div>
              );
            })}

          {!loading &&
            feedItems.map((feedItem: PostCardType, index: number) => {
              return (
                <ProductTile
                  type={feedItem.type}
                  id={feedItem.id}
                  key={index}
                  title={
                    feedItem.vendor?.name ||
                    feedItem.title ||
                    feedItem.product?.title
                  }
                  subtitle={(feedItem?.product?.originCounty && feedItem?.product?.originState) ? feedItem.product.originCounty + ' County, ' + feedItem.product.originState : ''}
                  avatar={
                    feedItem.avatar ||
                    feedItem?.vendor?.avatar ||
                    feedItem?.product?.images[0]
                  }
                  avatarLabel={feedItem.avatarLabel || feedItem?.vendor?.name}
                  textImage={feedItem.image}
                  text={feedItem.text || feedItem.title}
                  product={feedItem.product}
                  vendor={feedItem.vendor}
                  getFeed={awaitGetFeed}
                  router={router}
                />
              );
            })}
        </div>
      </section>
    </div>
  );
};
export default HomeFeed;
