import {IonBackButton, IonButtons, IonSkeletonText, useIonRouter} from '@ionic/react';
import {useEffect, useState} from 'react';
import {termSearch} from '../../Api/api.search';
import AppHeader from '../../Components/Header/AppHeader';
import Pagination from '../../Components/Pagination/Pagination';
import {UseQuery} from '../../Helpers/Hooks/UseQuery';
import Product from '../Product/ProductClass';
import ProductTile from "../../NewDomains/Product/Components/ProductTile";

const SearchView = () => {
  const router = useIonRouter();
  const urlQuery = UseQuery();
  const q = urlQuery.get('q') || undefined;

  const [loaded, setLoaded] = useState(false);
  const resultsBase: Array<Product> = [];
  const [results, setResults] = useState(resultsBase);
  const [totalResults, setTotalResults] = useState(0);
  const [paginationPage, setPaginationPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);


  const perPage = 18;
  useEffect(() => {
    if (q) {
      setLoaded(false);
      termSearch(q, paginationPage, perPage).then((searchResults: any) => {
        setLoaded(true);
        if (searchResults) {
          setResults(searchResults.results[0]);
          setTotalCount(searchResults.results[1]);
          setTotalResults(Math.ceil(searchResults.results[1] / perPage));
          let gtagItems = searchResults.results[0].map((item: Product, index: number) => {

            return {
              "id": item.id,
              "name": item.title,
              "list_name": "Search Results",
              "brand": item.vendor?.name,
              "list_position": index,
              "quantity": 1,
              "price": item?.variants?.[0].salePrice || item?.variants?.[0].price
            };
          });
          window.gtag('event', 'view_item_list', {
            "items": gtagItems
          });

        }
      });
    }
  }, [q, setResults, setLoaded, setTotalResults, paginationPage, perPage]);


  const paginationLink = `/search/?q=${q}&page={page}`;

  return (
    <>
      <AppHeader/>

      <main className='relative max-w-screen-xl px-4 py-4 mx-auto'>
        <IonButtons slot='start'>
          <IonBackButton defaultHref='/'/>
        </IonButtons>
        <h1 className='text-xl font-bold leading-tight'>
          Search Results for "{q}"
        </h1>

        <p className='mb-4'>Found {totalCount} results</p>

        <section
          className='md:p-10 grid grid-cols-1 gap-3 px-2 md:p= md:grid-cols-2 md:gap-6 lg:grid-cols-2 xl:grid-cols-3'>
          {!loaded &&
            [1, 2, 3, 4, 5, 6].map((i) => {
              return (
                <div key={i} className='bg-white rounded-lg shadow-md'>
                  <IonSkeletonText animated style={{height: '120px'}}/>
                  <div className='p-4'>
                    <IonSkeletonText
                      animated
                      style={{height: '20px', width: '86%'}}
                    />
                    <IonSkeletonText
                      animated
                      style={{width: '54%', height: '10px'}}
                    />
                  </div>
                  <div className='flex p-2 space-x-2'>
                    <IonSkeletonText style={{height: '40px'}}/>
                    <IonSkeletonText style={{height: '40px'}}/>
                  </div>
                </div>
              );
            })}

          {results.map((product: Product) => {
            return (
              <ProductTile
              type={product.ribbonType}
              id={product.id}
              key={product.id}
              title={
                product.vendor?.name ||
                product.title
              }
              subtitle={(product.originCounty && product.originState) ? product.originCounty + ' County, ' + product.originState : ''}
              avatar={
                product.vendor?.avatar ||
                product?.images[0]
              }
              avatarLabel={product.vendor?.name}
              likeCount={0}
              commentCount={0}
              textImage={product.images[0]}
              text={product.title}
              product={product}
              vendor={product.vendor}
              customerliked={product.isFavorite}
              likecount={0}
              router={router}
            />)
          })}

        </section>
        <Pagination
          current={paginationPage}
          totalPages={totalResults}
          link={paginationLink}
          onPageChange={(page) => {
            setPaginationPage(page);
          }}
        />
      </main>
    </>
  );
};
export default SearchView;
