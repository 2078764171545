import PrivatePage from "../../../Components/Page/PrivatePage";
import {Helmet} from 'react-helmet';
import {IonSkeletonText, useIonRouter} from "@ionic/react";
import {useListBundles} from "../bundle.actions";
import {LoadingState, useAppStore} from "../../store";
import BundleModel from "../bundle.model";
import GeneralHeader from "../../../Components/Header/GeneralHeader";

const BundlesPage = () => {
  const router = useIonRouter();
  const [bundles, bundlesLoadingState] = useListBundles();
  const update = useAppStore(s => s.update);

  const getBundles = () => bundles ?? [];
  const handleBundleClick = (bundle: BundleModel) => {
    update({activeBundle: bundle})
    router.push(`/bundles/${bundle.slug}`);
  }

  return (
    <PrivatePage>
      <Helmet>
        <title>Bundles | Market Wagon</title>
        <link rel="canonical" href={`https://shop.marketwagon.com/bundles`}/>
      </Helmet>


      <GeneralHeader title='Bundles' backHref='/shop'/>

      <section>
        <div className='py-4 md:py-8 bg-gray-100'>
          <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
            <div className='lg:text-center'>
              <p className='mt-2 text-xl leading-8 font-extrabold tracking-tight text-mw-orange md:text-4xl'>
                Check out our Bundles!
              </p>
              <p className='mt-4 text-base md:text-xl text-gray-500 md:mx-auto'>
                Pre-selected baskets of the finest Local Food
              </p>
            </div>
            <div className='w-full flex items-center'>
              <div
                className="mt-6 md:mt-10 flex flex-col items-start space-y-4 mx-auto">
                {bundlesLoadingState === LoadingState.LOADED && getBundles() && getBundles().map((bundle, index) => {
                  return (
                    <button
                      key={index}
                      title={`View ${bundle.title}`}
                      onClick={() => {
                        handleBundleClick(bundle);
                      }}
                      className='shadow-sm w-full hover:bg-gray-50 hover:shadow-lg rounded bg-white flex h-32 md:h-44'
                    >
                      <img
                        alt={bundle.title}
                        className='w-32 h-32 md:w-44 md:h-44 rounded-l object-cover'
                        src={bundle.image}
                      />

                      <div className='flex-col p-2 content-center h-full'>
                        <h3 className='text-gray-900 font-semibold md:font-medium text-center md:text-xl'>
                          {bundle.title}
                        </h3>
                        <div className='hidden md:block max-w-sm text-gray-600 text-sm tracking-tight p-2'>
                          {bundle.description}
                        </div>
                      </div>
                    </button>
                  );
                })}
                {bundlesLoadingState === LoadingState.LOADING &&
                  (<>
                    <IonSkeletonText
                      animated
                      className='w-80 h-32 md:w-96 md:h-44 shadow-sm rounded'
                    />
                    <IonSkeletonText
                      animated
                      className='w-80 h-32 md:w-96 md:h-44 shadow-sm rounded'
                    />
                    <IonSkeletonText
                      animated
                      className='w-80 h-32 md:w-96 md:h-44 shadow-sm rounded'
                    />
                  </>)
                }
              </div>
            </div>

          </div>
        </div>
      </section>
    </PrivatePage>
  );
};

export default BundlesPage;
