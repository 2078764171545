import Vendor from '../Vendor/VendorClass';
import {VariantType} from './types';

export class Variant {
  id: number;
  title: string;
  inventory: number;
  price: number;
  salePrice: number;
  isSubscribed?: boolean;
  preOrderDate: string;

  constructor(starter: VariantType) {
    this.id = starter.id;
    this.title = starter.title;
    this.inventory = starter.inventory;
    this.price = starter.price;
    this.isSubscribed = starter.isSubscribed;
    this.salePrice = starter.salePrice;
    this.preOrderDate = starter.preOrderDate;
  }
}

type MarketType = { id: string; name: string };

class Product {
  id?: number;
  slug?: string;
  created: Date;
  previousOrderCount: number = 0;
  previousVendorOrderCount: number = 0;
  isFavorite: boolean = false;
  tags: string = '';
  ratingCount: number = 0;
  avgRating: number = 0;
  ribbonText?: string;
  ribbonType?: string;
  discountNotes?: string;
  title: string;
  images: Array<string> = [];
  description: string;
  variants: Array<VariantType> = [];
  vendor?: Vendor;
  availableMarkets?: Array<MarketType> = [];
  badge: number = 0;
  isPreOrder: boolean = false;
  originCountry?: string;
  originState?: string;
  originCounty?: string;
  newDescription?: string;
  processor?: string;

  constructor(starter: any) {
    this.id = starter.id;
    this.slug = starter.slug;
    this.title = starter.title || 'Title Missing';
    this.created = starter.created ? new Date(starter.created) : new Date();
    // Previous Order Counts
    this.previousOrderCount = starter.previousOrderCount || 0;
    this.previousVendorOrderCount = starter.previousVendorOrderCount || 0;
    // Is product Favorite?
    this.isFavorite = !!starter.isFavorite;
    // Setup tags
    this.tags = starter.tags || '';
    this.images = starter.images instanceof Array ? starter.images : [];
    this.description = starter.description || undefined;
    this.variants =
      starter.variants instanceof Array
        ? starter.variants.map((v: VariantType) => new Variant(v))
        : [];
    this.badge = starter.badge;
    this.vendor = starter.vendor ? new Vendor(starter.vendor) : undefined;
    this.avgRating = starter.avgRating || 0;
    this.ratingCount = starter.ratingCount || 0;
    this.ribbonText = starter.ribbonText;
    this.ribbonType = starter.ribbonType;
    this.discountNotes = starter.discountNotes;
    this.availableMarkets = starter.availableMarkets
      ? starter.availableMarkets
      : [];
    this.isPreOrder = starter.isPreOrder;
    this.originCountry = starter.originCountry;
    this.originState = starter.originState;
    this.originCounty = starter.originCounty;
    this.originCounty = starter.originCounty;
    this.newDescription = starter.newDescription;
    this.processor = starter.processor;
  }

  get displayPrice(): string {
    if (this.variants.length) {
      return this.variants[0].salePrice ? parseFloat(`${this.variants[0].salePrice}`).toFixed(2) : parseFloat(`${this.variants[0].price}`).toFixed(2);
    }
    return '';
  }
}

export default Product;
