import {GET} from './Api';

export const searchAutoComplete = async (term: string) => {
  const call = await GET(`/search/new/auto-complete/${term}`);
  return call.payload;
};


export const searchVendors = async (
  term: string,
) => {
  const call = await GET(`/search/vendors/${term}`);
  return call.payload;
};

export const termSearch = async (
  term: string,
  page: number,
  perPage: number
) => {
  const call = await GET(`/search/new/query/${term}`, page, perPage);

  return {
    results: call.payload,
  };
};

export const suggestionSearch = async (
  idOrSlug: any,
  page: number,
  perPage: number
) => {
  const call = await GET(`/products/${idOrSlug}/suggestions`, page, perPage);

  return {
    results: call.payload,
  };
};
export const nameSearch = async (
  term: string,
) => {
  try {
    const call = await GET(`/vendors/search/${term}`);
    return {
      results: call.payload,
    };
  } catch (error) {
    return error;
  }
};
