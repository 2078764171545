import {IonIcon, useIonModal} from '@ionic/react';
import {apps} from 'ionicons/icons';
import {Link} from 'react-router-dom';
import MWChip from '../../Components/MWChip/MWChip';
import CollectionsModal from './CollectionModal';
import styles from './Collection.module.css';
import {CollectionSubType, CollectionType} from './types';

const CollectionNavigation = ({...props}: any) => {
  const [presentCollectionsModal, dismissCollectionsModal] = useIonModal(CollectionsModal, {
    onDismiss: () => {
      dismissCollectionsModal();
    }
  });

  const determineActiveClasses = () => {
    let classString = `${styles.CollectionItem}`;

    if (!props.activeCollection && !props.specialTab) {
      classString += ` ${styles.CollectionItemActive}`
    }

    return classString;
  };

  return (
    <>
      <nav
        aria-label='Collections Nav'
        className='relative flex items-center overflow-x-auto bg-white shadow-md bg-opacity-80'
      >
        {props.showCollectionsButton &&
          <div
            className='z-20 flex items-center pl-2 pr-2 space-x-4 flex-fixed'
            aria-hidden='true'
          >
            <button
              aria-label='Expand Collections'
              className='text-xs text-gray-500 focus:ring-2 group active:text-green-500'
              onClick={() => presentCollectionsModal()}
            >
              <IonIcon
                icon={apps}
                className='p-1 bg-white shadow-md bg-rounded-md'
              />
            </button>
          </div>
        }

        <div
          className={`flex flex-nowrap space-x-4 w-auto px-2 md:px-4 md:items-center overflow-y-hidden`}
        >
          {props.specialTab && (
            <div className={`${styles.CollectionItem} ${styles.SpecialTab}`}>
              <div className={`${styles.SpecialTabLabel}`}>
                {props.specialTab}
              </div>
            </div>
          )}

          {props.showFreshFeedButton &&
            <Link

              id='fresh-feed'
              className={determineActiveClasses()}
              style={{color: '#DA2C27'}}
              to={`/`}
            >
              Fresh Feed
            </Link>
          }

          {!!props.collections.length && props.collections.filter((c: CollectionType) => c.showInMainMenu).map((collection: any, index: number) => {
            return (

              <Link
                id={`collection-link-${collection.slug}`}
                key={index}
                className={` ${styles.CollectionItem} ${
                  props.activeCollection?.title === collection.title
                    ? styles.CollectionItemActive
                    : ''
                }`}
                style={{color: `${(collection.slug === 'on-sale') || (collection.slug === 'most-popular') || (collection.slug === 'newest-items') ? '#DA2C27' : ''}`}}
                to={`/collections/${collection.slug}`}
                onClick={() => props.onCollectionSelect(collection)}
              >
                <div className='relative'>
                  {collection.title}
                  {collection.slug === 'newest-items' && collection.newItemsCount != null && collection.newItemsCount > 0 && (
                    <div
                      style={{fontSize: '10px', lineHeight: '11px'}}
                      className={`absolute -top-0.5 shadow bg-red-500 text-white rounded-full font-semibold text-center h-3 ${collection.newItemsCount > 9 ? 'w-4 -right-4' : 'w-3 -right-3'}`}>{collection.newItemsCount}</div>
                  )}
                </div>
              </Link>
            );
          })}
        </div>
      </nav>

      {props.activeCollection?.children && props.activeCollection?.children.length > 0 && (
        <nav className='sticky py-2 overflow-y-auto bg-opacity-80 bg-white top-14 md:pt-3'>
          <div className='flex items-center w-auto px-4 space-x-2 flex-nowrap md:items-center'>
            <MWChip
              className='bg-white shadow-sm'
              label={`All ${props.activeCollection?.title}`}
              active={!props.activeChildCollection ? 'true' : 'false'}
              onClick={() => {
                props.onCollectionSelect(props.activeCollection);
                props.router.push(`/collections/${props.activeCollection.slug}`);
              }}
            />

            {props.activeCollection?.children.map(
              (subCollection: CollectionSubType) => {

                return (
                  <Link
                    key={subCollection.id}
                    to={`/collections/${subCollection.id}`}
                    onClick={props.handleChildCollectionSelect}
                  >
                    <MWChip
                      active={
                        props.activeChildCollection?.title === subCollection?.title
                          ? 'true'
                          : 'false'
                      }
                      className='bg-white shadow-md '
                      label={subCollection.title}
                    />
                  </Link>
                );
              }
            )}
          </div>
        </nav>
      )}
    </>
  );
}

export default CollectionNavigation;
