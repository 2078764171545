import {
  setupConfig,
  isPlatform,
  IonRouterOutlet,
  useIonAlert,
} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {Route, Redirect} from 'react-router';
import Page404 from './Domains/404/404';
import BundlePage from './NewDomains/Bundle/Components/BundlePage';
import BundlesPage from './NewDomains/Bundle/Components/BundlesPage';
import CollectionsPage from './Domains/Collection/Collections';
import FavoritesPage from './Domains/Favorites/Favorites';
import HomePage from './Domains/Home/Home';
import HubDetailsPage from './NewDomains/Hub/Components/HubDetailsPage';
import HubsPage from './NewDomains/Hub/Components/HubsPage';
import InboxPage from './Domains/Inbox/Inbox';
import MorePage from './Domains/More/More';
import PasswordResetPage from './Domains/Onboard/PasswordReset';
import OrderCompletePage from './Domains/Orders/OrderComplete';
import ProductView from './Domains/Product/Product';
import SearchPage from './Domains/Search/Search';
import VendorsPage from './Domains/Vendor/VendorsPage';
import {observeVersionChange} from './Domains/Version/VersionCheck';
import URLParamRedirector from './Helpers/URLParamRedirector';
import Modal from './Components/Modal/Modal';
import HelpPage from './Domains/Help/HelpPage';
import SupportPage from './Domains/Help/SupportPage';
import FAQPage from './Domains/Help/FAQPage';
import {useState, useEffect, useCallback} from 'react';
import {useStoreState} from 'pullstate';
import {loadCollections} from './Domains/Collection/CollectionStore';
import {logout, setUserHub, UserStore} from './Domains/User/UserStore';
import {
  CartStore,
  CartStoreInit,
  isCartInitialized,
} from './Domains/Cart/CartStore';
import {queryAuth} from './Api/api.auth';
import AboutPage from './Domains/About/AboutPage';
import BlogEntry from './Domains/Blog/BlogEntry';
import BlogPage from './Domains/Blog/BlogPage';
import VendorCollectionPage from './Domains/Vendor/VendorCollectionsPage';

dayjs.extend(relativeTime);

/**
 * Configure IONIC
 * - set desktop to Use iOS theme
 */
const getConfig = (): any => {
  const config: any = {
    autoFocusAssist: false,
    scrollAssist: false,
  };
  if (isPlatform('desktop')) {
    config.mode = 'ios';
  }
  return config;
};

// Run Ionic configure
setupConfig(getConfig());

// Start watching for version changes
observeVersionChange();

/**
 * Main React App Function
 */
function App(props: any) {
  const userState = useStoreState(UserStore);
  const [presentAlert] = useIonAlert();
  const [hasMounted, setHasMounted] = useState(false);
  const cartInitialized = isCartInitialized(CartStore);
  const [modals, setModals] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!hasMounted) {
      if (error === null && props.mountWithError) {
        setError(props.mountWithError);
      }

      setHasMounted(true);
    }
  }, [error, hasMounted, props.mountWithError]);

  useEffect(() => {
    if (hasMounted && error !== null) {
      presentAlert({
        message: error,
        buttons: [
          {
            text: 'Okay',
          },
        ],
      }).then();
    }
  }, [error, hasMounted, presentAlert]);

  // Given a modal "type" (string), merge the next modal with all existing modals.
  const handleModalEvent = useCallback(
    (modal: any) => {
      let tempModals: any = [];

      if (modals !== null) {
        tempModals = [...modals];
      }

      tempModals.push(modal);
      setModals(tempModals);
    },
    [modals]
  );

  // Given an index in the modal array, remove the modal since it's "closed".
  const handleModalClose = (index: number) => {
    let tempModals: any = [];

    if (modals !== null) {
      tempModals = [...modals];
    }

    tempModals.splice(index, 1);
    setModals(tempModals);
  };

  useEffect(() => {
    if (hasMounted && userState.hubs && userState.hubs.length) {
      loadCollections().then();
    }
  }, [hasMounted, userState.hubs]);

  /**
   * If the User has a State
   * user has hubs and hubs > 0
   * Initialize the Cart Store
   */
  useEffect(() => {
    if (
      hasMounted &&
      userState &&
      userState?.hubs &&
      userState.hubs.length > 0 &&
      !cartInitialized
    ) {
      CartStoreInit().then();
    }
  }, [cartInitialized, hasMounted, userState, userState.hubs]);

  // Set up a function that runs every 15 to query the users auth variables.
  // If that query returns false (no hub id or no customer id) we can assume that user's session has expired.
  // Flash an alert and send em packing!...to the login screen.
  useEffect(() => {
    let intervalId: any = null;

    if (hasMounted) {
      intervalId = setInterval(() => {
        if (userState && userState.hubs && userState.hubs.length > 0) {
          queryAuth().then((res) => {
            if (typeof res !== 'number') {
              presentAlert(
                'Your session has expired. Please login to continue.',
                [
                  {
                    text: 'Okay',
                    handler() {
                      logout().then();
                    },
                  },
                ]
              ).then();
            }
          });

          setUserHub(userState.hubs[0]).then();
        }
      }, 900000);
    }

    return () => clearInterval(intervalId);
  });

  useEffect(() => {
    if (hasMounted && userState.hubs && userState.hubs?.length > 0) {
      setUserHub(userState.hubs[0]).then();
    }
  }, [hasMounted, userState.hubs]);

  // Setup event listeners to listen for modalEvents and process them as needed
  useEffect(() => {
    window.addEventListener('modalEvent', (e: any) =>
      handleModalEvent(e.detail)
    );

    return () => {
      window.removeEventListener('modalEvent', (e: any) =>
        handleModalEvent(e.detail)
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonReactRouter>
      <main
        id='app-main'
        aria-label='Market Wagon Web App'
        className='z-0 bg-gray-100'
        style={{
          minHeight: `calc(100vh - 40px)`,
        }}
      >
        <URLParamRedirector/>
        <IonRouterOutlet className='relative z-0' animated={false}>
          <Route path='/' render={() => <Redirect to='/shop'/>} exact/>
          <Route
            path='/?q=:term'
            render={() => <Redirect to='/search?q='/>}
            exact
          />
          <Route path='/inbox' component={InboxPage} exact/>
          <Route path='/shop' component={HomePage} exact/>
          <Route path='/hubs' component={HubsPage} exact/>
          <Route path='/hubs/:id' component={HubDetailsPage}/>
          <Route path='/search/' component={SearchPage}/>
          <Route path='/collections' component={CollectionsPage} exact/>
          <Route
            path='/collections/:collectionSlug'
            component={HomePage}
            exact
          />
          <Route
            path='/collections/vendor/:vendorId'
            component={VendorCollectionPage}
            exact
          />
          <Route path='/more' component={MorePage} exact/>
          <Route path='/favorites' component={FavoritesPage} exact/>
          <Route path='/login' render={() => <Redirect to='/shop'/>} exact/>
          <Route path='/bundles/:bundleId' component={BundlePage}/>
          <Route path='/product/:slug' component={ProductView}/>
          <Route path='/products' component={ProductView}/>
          <Route path='/products/:slug' component={ProductView}/>
          <Route path='/vendors' component={VendorsPage} exact/>
          <Route path='/vendors/:vendorId' component={HomePage} exact/>
          <Route path='/bundles' component={BundlesPage} exact/>
          <Route path='/subscription' component={HomePage} exact/>
          <Route path='/profile' component={HomePage} exact/>
          <Route path='/account' component={HomePage} exact/>
          <Route
            path='/password-reset/:jwt'
            component={PasswordResetPage}
            exact
          />
          <Route
            path='/order-complete/:orderId'
            component={OrderCompletePage}
            exact
          />
          <Route path='/join' component={HomePage} exact/>
          <Route path='/gift-card' component={HomePage} exact/>
          <Route path='/wagon-pass' component={HomePage} exact/>
          <Route path='/about' component={AboutPage} exact/>
          <Route path='/blog' component={BlogPage} exact/>
          <Route path='/blog/:idOrSlug' component={BlogEntry} exact/>
          <Route path='/help' component={HelpPage} exact/>
          <Route path='/faq' component={FAQPage} exact/>
          <Route path='/support' component={SupportPage} exact/>
          <Route component={Page404}/>
        </IonRouterOutlet>
      </main>

      {modals &&
        modals.map((modal: any, index: number) => {
          return (
            <Modal
              key={index}
              data={modal}
              onModalClose={() => {
                handleModalClose(index);
              }}
            />
          );
        })}
    </IonReactRouter>
  );
}

export default App;
