import {useIonAlert, useIonLoading} from "@ionic/react";
import {addToCart} from "../../Domains/Cart/CartStore";
import MWButton from "../MWButton/MWButton";

const AddToCartButton = ({...props}: any) => {
  const buttonType: string = props.buttonType;
  const productId: number = props.productId;
  const qty: number = 1 | props.qty;
  const variantId: number = props.variantId;
  const [presentAlert] = useIonAlert();
  const [showLoading, dismissLoading] = useIonLoading();

  const tinyStyles = 'w-auto px-1 py-1 mt-1 text-xs font-semibold text-green-100 bg-green-600 rounded-lg shadow-sm';
  const iconStyles = 'w-10 h-10 p-2 mt-1 text-xs font-semibold bg-green-600 rounded-lg shadow-sm flex-initial';

  const handleAddToCart = async () => {
    showLoading('Adding to your cart...').then();

    if (!productId) {
      presentAlert('There was an error adding this item to your cart. Please try again later.').then();
      return false;
    }

    if (props.customOnClick) {
      props.customOnClick();
      dismissLoading().then();
      return false;
    }

    if (productId && qty && variantId) {
      try {
        await addToCart({
          productId: Number(productId),
          qty: qty,
          variantId: variantId
        })

        window.gtag('event', 'add_to_cart', {
          id: productId,
          quantity: qty,
        });

        dismissLoading().then();
        return true;
      } catch (e) {
        console.error(e);
        dismissLoading().then();
        presentAlert('There was an error adding this item to your cart. Please try again later.').then();
        return false;
      }
    }

    return true;
  }

  if (buttonType === 'tiny') {
    return (
      <MWButton
        title='Add to Cart'
        bg-gray-200
        className={`
                    ${tinyStyles}
                    ${props.additionalStyles ?? null}
                `}
        onClick={handleAddToCart}
      >
        Add to Cart
      </MWButton>
    );
  } else if (buttonType === 'icon') {
    return (
      <button
        title='Add to Cart'
        className={`
                    ${iconStyles}
                    ${props.additionalStyles ?? null}
                `}
        onClick={handleAddToCart}
      >
        <img className='w-full' src="/images/add-to-cart.svg" alt='Add To Cart'/>
      </button>
    )
  } else {
    return <></>;
  }
}

export default AddToCartButton;
