import {useEffect, useState} from 'react';
import {CollectionStore} from './CollectionStore';
import Product from '../Product/ProductClass';
import {IonSkeletonText, useIonRouter} from '@ionic/react';
import {Helmet} from 'react-helmet';
import {getCollectionProducts} from '../../Api/api.collections';
import Pagination from '../../Components/Pagination/Pagination';
import {UseQuery} from '../../Helpers/Hooks/UseQuery';
import ProductTile from "../../NewDomains/Product/Components/ProductTile";

const CollectionList = () => {
    const router = useIonRouter();
    const collectionState = CollectionStore.useState((s) => s);
    const [localProducts, setProducts] = useState<Array<Product>>([]);
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    let urlQuery = UseQuery();
    const page: any = parseInt(urlQuery.get('page') || '1');

    const perPage = 18;

    useEffect(() => {
        let ignoreCollectionId: number | null = null;
        const collectionId = collectionState.activeChild || collectionState.activeCollection;

        setLoading(true);

        getCollectionProducts(collectionId, perPage, page).then((res: [Array<Product>, number]) => {
          if (ignoreCollectionId !== collectionId) {
            setProducts(res[0]);
            setLoading(false);
            setTotalPages(Math.ceil(res[1] / perPage));
          }

          let gtagItems = res[0].map((item: Product, index: number) => {
            return {
              "id": item.id,
              "name": item.title,
              "list_name": collectionState.activeChild ? collectionState.activeChild.title : collectionState.activeCollection.title,
              "brand": item.vendor?.name,
              "list_position": index,
              "quantity": 1,
              "price": item?.variants?.[0].salePrice || item?.variants?.[0].price
            };
          });

          window.gtag('event', 'view_item_list', {
            "items": gtagItems
          });
        }).catch((e: any) => {
          console.error(e.message);
          if (ignoreCollectionId !== collectionId) {
            setLoading(false);
          }
        });

        return () => {
          ignoreCollectionId = collectionId;
        };
      }, [collectionState.activeCollection, collectionState.activeChild, page]
    )
    ;

    const paginationLink = `/collections/${collectionState.activeChild ? collectionState.activeChild.id : collectionState.activeCollection.slug}?page={page}`;

    return (
      <>
        <Helmet>
          {collectionState.activeChild ? (
            <title>
              {`${collectionState.activeChild.title} in ${collectionState.activeCollection.title} | Market Wagon`}
            </title>
          ) : (
            <title>
              {`${collectionState.activeCollection.title} | Market Wagon`}
            </title>
          )}
        </Helmet>

        <main className='max-w-screen-xl px-2 py-4 mx-auto'>
          <section
            className='md:p-10 grid grid-cols-1 gap-3 px-2 md:p= md:grid-cols-2 md:gap-6 lg:grid-cols-2 xl:grid-cols-3'>
            {loading &&
              [1, 2, 3, 4, 5, 6].map((i) => {
                return (
                  <div key={i} className='bg-white rounded-lg shadow-md'>
                    <IonSkeletonText animated style={{height: '120px'}}/>

                    <div className='p-4'>
                      <IonSkeletonText
                        animated
                        style={{height: '20px', width: '86%'}}
                      />

                      <IonSkeletonText
                        animated
                        style={{width: '54%', height: '10px'}}
                      />
                    </div>

                    <div className='flex p-2 space-x-2'>
                      <IonSkeletonText style={{height: '40px'}}/>
                      <IonSkeletonText style={{height: '40px'}}/>
                    </div>
                  </div>
                );
              })}

            {!loading &&
              localProducts.map(
                (product: Product) => {
                  return (
                    <ProductTile
                      type={product.ribbonType}
                      id={product.id}
                      key={product.id}
                      title={
                        product.vendor?.name ||
                        product.title
                      }
                      subtitle={(product.originCounty && product.originState) ? product.originCounty + ' County, ' + product.originState : ''}
                      avatar={
                        product.vendor?.avatar ||
                        product?.images[0]
                      }
                      avatarLabel={product.vendor?.name}
                      likeCount={0}
                      commentCount={0}
                      textImage={product.images[0]}
                      text={product.title}
                      product={product}
                      vendor={product.vendor}
                      customerliked={product.isFavorite}
                      likecount={0}
                      router={router}
                    />
                  );
                }
              )}
          </section>

          {localProducts.length === 0 && !loading && (
            <div className='alight-center text-center text-xl'>
              <p className='mb-5'>
                Sorry, there aren't any{' '}
                <span className='font-bold'>
                  {collectionState.activeChild?.title}
                </span>{' '}
                products in your area right now.
              </p>

              <a
                href={`/collections/${collectionState.activeCollection.slug}`}
                className='text-sm text-green-600'
              >
                View All {collectionState.activeCollection.title} &#8594;
              </a>
            </div>
          )}

          {totalPages > 1 && (
            <Pagination
              current={page}
              totalPages={totalPages}
              link={paginationLink}
            />
          )}
        </main>
      </>
    );
  }
;
export default CollectionList;
