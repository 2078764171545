export default class VariantModel {
  id: string;
  title: string;
  inventory?: number;
  price?: number;
  salePrice?: number;
  preOrderDate?: Date;

  constructor(input: any) {
    this.id = input.id;
    this.title = input.title;
    this.inventory = input.inventory;
    this.price = input.price;
    this.salePrice = input.salePrice;
    this.preOrderDate = input.preOrderDate ? new Date(input.preOrderDate) : undefined;
  }
}
