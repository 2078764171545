import {IonIcon, IonItem, IonList, IonSkeletonText, useIonModal} from "@ionic/react";
import {useStoreState} from "pullstate";
import {useCallback, useEffect, useState} from "react";
import {getVendor, getVendorPosts, getVendorProducts} from "../../Api/api.vendor";
import StatBlock from "../../Components/StatBlock/StatBlock";
import VendorHeaderToolbar from "./VendorHeaderToolbar";
import {VendorStore, setVendorStoreProperty, VendorType} from "./VendorStore";
import {trophyOutline} from 'ionicons/icons';
import dayjs from "dayjs";
import HorizontalScroller from "../../Components/HorizontalScroller/HorizontalScroller";
import {PostCardType} from "../Post/types";
import ProductItem from "../Product/ProductItem/ProductItem";
import VendorEndorseModal from "./VendorEndorseModal";
import {Helmet} from 'react-helmet';
import VendorHero from "./Components/VendorHero";
import ProductTile from "../../NewDomains/Product/Components/ProductTile";

const VendorModal = ({...props}: any) => {
  const vendor = useStoreState(VendorStore, s => s.vendor);
  const vendorProducts = useStoreState(VendorStore, s => s.products);
  const vendorPosts = useStoreState(VendorStore, s => s.posts);
  const [hasMounted, setHasMounted] = useState(false);
  const pathName = window.location.pathname || null;
  const searchParams = window.location.search || null;

  const [presentEndorseModal, dismissEndorseModal] = useIonModal(VendorEndorseModal, {
    onDismiss: () => {
      dismissEndorseModal();
    }
  });

  const awaitGetVendorDetails = useCallback(async () => {
    await getVendor(vendor.id).then((res: any) => {
      setVendorStoreProperty('vendor', res as VendorType);
      return true;
    }).catch(() => {
      console.error('error fetching details');
      return false;
    });
  }, [vendor.id]);

  const awaitGetVendorProducts = useCallback(async () => {
    try {
      await getVendorProducts(vendor.id, 1).then((res: any) => {
        setVendorStoreProperty('products', res);
      });

      return true;
    } catch (e) {
      console.error('Error getting vendor products, please try again later');
      return false;
    }
  }, [vendor.id]);

  const awaitGetVendorPosts = useCallback(async () => {
    try {
      await getVendorPosts(vendor.id).then((res: any) => {
        setVendorStoreProperty('posts', res);
      });

      return true;
    } catch (e) {
      console.error('Error getting vendor posts, please try again later');
      return false;
    }
  }, [vendor.id]);

  useEffect(() => {
    if (!hasMounted && vendor.id) {
      awaitGetVendorDetails().then();
      awaitGetVendorProducts().then();
      awaitGetVendorPosts().then();

      setHasMounted(true);
    }
  }, [awaitGetVendorDetails, awaitGetVendorPosts, awaitGetVendorProducts, hasMounted, vendor.id]);

  const handleDismiss = () => {
    props.onDismiss();
  }

  return (
    <div className="h-full overflow-hidden pb-10 md:rounded-lg">
      <Helmet>
        <title>{vendor?.name ? vendor.name : 'Vendor'} Details</title>
        <link rel="canonical"
              href={`https://shop.marketwagon.com${pathName !== null ? pathName : ''}${searchParams !== null ? searchParams : ''}`}/>
      </Helmet>

      <VendorHeaderToolbar
        vendor={vendor}
        isModal={true}
        onDismiss={handleDismiss}
      />

      <div className='bg-white h-full overflow-y-scroll'>
        <VendorHero
          isLoading={hasMounted}
          compact={props.compact}
          backgroundImage={vendor?.heroImage}
          avatar={vendor?.avatar}
          name={vendor?.name}
          children={props.children}
          description={vendor?.description}
        />

        <div className='flex items-center justify-center px-6 mb-6 space-x-4 stats'>
          <>
            {!hasMounted &&
              <StatBlock
                value={
                  <IonSkeletonText
                    style={{height: '32px', width: '32px'}}
                    className='rounded-lg'
                    animated={true}
                  />
                }
                label='Products'
              />
            }

            {hasMounted &&
              <StatBlock
                value={vendor?.products}
                label='Products'
              />
            }
          </>

          <>
            {!hasMounted &&
              <StatBlock
                value={
                  <IonSkeletonText
                    style={{height: '32px', width: '32px'}}
                    className='rounded-lg'
                    animated={true}
                  />
                }
                label='Followers'
              />
            }

            {hasMounted &&
              <StatBlock
                value={vendor?.followers}
                label='Followers'
              />
            }
          </>

          <>
            {!hasMounted &&
              <StatBlock
                value={
                  <IonSkeletonText
                    style={{height: '32px', width: '32px'}}
                    className='rounded-lg'
                    animated={true}
                  />
                }
                label='Endorsements'
              />
            }

            {hasMounted &&
              <StatBlock
                value={parseFloat(vendor?.endorsementScore).toFixed(1)}
                label='Endorsements'
              />
            }
          </>

          <>
            {!hasMounted &&
              <StatBlock
                value={
                  <IonSkeletonText
                    style={{height: '32px', width: '32px'}}
                    className='rounded-lg'
                    animated={true}
                  />
                }
                label='Endorse'
              />
            }

            {hasMounted &&
              <button
                onClick={() => presentEndorseModal()}
                className='p-2 rounded-md'
              >
                <StatBlock
                  value={
                    <div className='flex items-center justify-center w-8 h-8 bg-green-100 rounded-full'>
                      <IonIcon
                        icon={trophyOutline}
                        className='text-xl text-green-600'
                      />
                    </div>
                  }
                  label={
                    <span className='font-semibold text-green-600'>Endorse</span>
                  }
                />
              </button>
            }
          </>
        </div>

        <IonList className='max-w-screen-md pb-4 mx-auto md:rounded-md'>
          <div className='flex items-center justify-between w-full px-4'>
            <span className='text-xl font-bold'>Popular</span>

            {!hasMounted && vendorProducts.products === null &&
              <IonSkeletonText
                style={{height: '17px', width: '53px'}}
                className='rounded-lg'
                animated={true}
              />
            }

            {hasMounted && vendorProducts.products !== null &&
              <IonItem
                onClick={() => {
                  props.router.push(`/collections/vendor/${vendor.id}`)
                  props.onDismiss();
                }}
                className='text-sm font-semibold text-green-600 cursor-pointer'
              >
                Shop all
              </IonItem>
            }
          </div>

          {/* Popular products loader */}
          {!hasMounted && vendorProducts.products === null &&
            <div className='relative flex items-center overflow-x-auto py-2'>
              <div className='flex flex-nowrap space-x-2'>
                <div className='ml-4'>
                  <IonSkeletonText
                    style={{height: '80px', width: '120px'}}
                    className='rounded-lg mb-2'
                    animated={true}
                  />

                  <IonSkeletonText
                    style={{height: '15px', width: '75px'}}
                    className='rounded-lg mb-2'
                    animated={true}
                  />

                  <IonSkeletonText
                    style={{height: '24px', width: '120px'}}
                    className='rounded-lg'
                    animated={true}
                  />
                </div>

                <div>
                  <IonSkeletonText
                    style={{height: '80px', width: '120px'}}
                    className='rounded-lg mb-2'
                    animated={true}
                  />

                  <IonSkeletonText
                    style={{height: '15px', width: '75px'}}
                    className='rounded-lg mb-2'
                    animated={true}
                  />

                  <IonSkeletonText
                    style={{height: '24px', width: '120px'}}
                    className='rounded-lg'
                    animated={true}
                  />
                </div>

                <div>
                  <IonSkeletonText
                    style={{height: '80px', width: '120px'}}
                    className='rounded-lg mb-2'
                    animated={true}
                  />

                  <IonSkeletonText
                    style={{height: '15px', width: '75px'}}
                    className='rounded-lg mb-2'
                    animated={true}
                  />

                  <IonSkeletonText
                    style={{height: '24px', width: '120px'}}
                    className='rounded-lg'
                    animated={true}
                  />
                </div>
              </div>
            </div>
          }

          {hasMounted && vendorProducts.products !== null &&
            <HorizontalScroller className='px-4 py-2' wrapperClass='space-x-2'>
              {vendorProducts.products.map(
                (product: any, index: number) => {
                  return (
                    <ProductItem
                      type='compact'
                      product={product}
                      key={`${index}-1`}
                    />
                  );
                }
              )}
            </HorizontalScroller>
          }
        </IonList>

        {/* Posts */}
        <IonList className='pt-4 pb-10 bg-gray-200'>
          <div className='flex items-center justify-between w-full px-4 max-w-screen-md mx-auto'>
            <span className='text-xl font-bold'>The Latest</span>
          </div>

          <div className='grid grid-cols-1 gap-4 p-4 md:grid-col-2 max-w-screen-md mx-auto'>
            {!hasMounted && vendorPosts === null &&
              <>
                <IonSkeletonText
                  style={{height: '340px', width: '343px'}}
                  className='rounded-lg'
                  animated={true}
                />

                <IonSkeletonText
                  style={{height: '340px', width: '343px'}}
                  className='rounded-lg'
                  animated={true}
                />

                <IonSkeletonText
                  style={{height: '340px', width: '343px'}}
                  className='rounded-lg'
                  animated={true}
                />
              </>
            }

            {hasMounted && vendorPosts !== null && vendorPosts.map((post: PostCardType, index: number) => {
              return (
                <ProductTile
                  id={post.id}
                  key={index}
                  title={post.vendor?.name}
                  subtitle={dayjs(new Date(post.created || '')).format(
                    'MMM D YYYY'
                  )}
                  avatar={post.vendor?.avatar}
                  avatarLabel={post.vendor?.name}
                  text={post.text || post.description}
                  textImage={post.textImage}
                  product={post.product}
                  type={post.type}
                  context={'vendor-feed'}
                />
              );
            })}

            {hasMounted && vendorPosts !== null && vendorPosts.length === 0 &&
              <p>No recent activity</p>
            }
          </div>
        </IonList>
      </div>
    </div>
  );
};
export default VendorModal;
