import {
  IonBadge,
  IonSegment,
  IonSegmentButton,
  IonSkeletonText,
  IonToolbar,
} from '@ionic/react';
import dayjs from 'dayjs';
import {useState, useEffect} from 'react';
import {
  getProductQuestions,
  getProductReviews,
} from '../../../Api/api.products';
import BeyondLocalBadge from '../../../Components/Badges/BeyondLocalBadge';
import GeneralStoreBadge from '../../../Components/Badges/GeneralStoreBadge';
import PhotoSlides from '../../../Components/PhotoSlides/PhotoSlides';
import Product from '../ProductClass';
import ProductQuestions from '../ProductQuestions';
import ProductReviews from '../ProductReviews';
import {ProductQuestionType, ProductReviewType} from '../types';
import ProductBuyBarWithQuantityButton from '../ProductItem/ProductBuyBarWithQtyBtn';

type ProductDetailViewProps = {
  product: Product | undefined;
  handleViewVendor: any;
  isLoading: any;
  compact: any;
  userState: any;
  handleViewHubDetails: any;
};

const ProductHero = ({...props}: ProductDetailViewProps) => {
  const product = props.product;
  const [activeTab, setActiveTab] = useState('reviews');
  const [questions, setQuestions] = useState<Array<ProductQuestionType>>([]);
  const [reviews, setReviews] = useState<Array<ProductReviewType>>([]);

  useEffect(() => {
    if (activeTab === 'reviews' && product?.id) {
      getProductReviews(product?.id).then((res) => {
        setReviews(res);
      });
    }
  }, [activeTab, product?.id]);

  useEffect(() => {
    if (activeTab === 'questions' && product?.id) {
      getProductQuestions(product?.id).then((res) => {
        setQuestions(res);
      });
    }
  }, [activeTab, product?.id]);
  return (
    <div>
      <main className='relative max-w-screen-md mx-auto mb-4 px-4 pb-2 bg-white'>
        {product && product.badge === 2 && !product.isPreOrder && (
          <BeyondLocalBadge classes='absolute z-20 h-auto right-10'/>
        )}

        {product && product.badge === 3 && !product.isPreOrder && (
          <GeneralStoreBadge classes='absolute z-20 h-auto right-10'/>
        )}

        {product && product.badge === 4 && !product.isPreOrder &&
          <div className='absolute z-20 right-10 shadow-xl bg-black h-auto p-2'>
            <p className='text-gray-100 font-semibold text-sm leading-tight tracking-tight'>
              Seeds <br/> <span className='text-gray-400'>and</span> <br/> Starts
            </p>
          </div>
        }


        {product && product.isPreOrder && (
          <div className='absolute z-20 h-auto right-10'>
            <img
              src='/images/badges/pre-order.png'
              alt={`Preorder ${product?.title} - Arrives: ${dayjs(
                product?.variants[0]?.preOrderDate.split('.000Z')[0]
              ).format('MM/DD/YYYY')}`}
              width='67'
              height='51'
            />
          </div>
        )}
        <div>
          {/* Product Photos */}
          {!!product && product.images?.length > 1 && (
            <PhotoSlides
              aria-label='Product Photos'
              slidesStyle={{height: '50vh'}}
              images={product.images}
            />
          )}
        </div>
        {!!product && product.images?.length === 1 && (
          <img
            src={product.images[0]}
            alt={product.title}
            className='w-full object-cover'
            style={{height: '333px'}}
          />
        )}
        {!product && (
          <div className='loading-mock-ui px-4 max-w-screen-md w-full mx-auto pt-5'>
            <IonSkeletonText animated className='h-12 w-11/12 mb-2'/>

            <IonSkeletonText animated className=' h-4 w-20 rounded-md mb-4'/>

            <div className='flex items-center justify-between mb-2'>
              <IonSkeletonText animated className=' h-4 w-20 rounded-md'/>
              <IonSkeletonText animated className=' h-4 w-20 rounded-md'/>
            </div>

            <div className='loading-bar-bar grid grid-cols-2 gap-3'>
              <IonSkeletonText animated className=' h-12 w-full rounded-md'/>
              <IonSkeletonText animated className=' h-12 w-full rounded-md'/>
            </div>
          </div>
        )}

        {!!product && (
          <section
            aria-label='Product Info'
            className='pt-2 pb-2 bg-white mw-container '
          >
            {/* Product Title  */}
            <h1 className='px-4 mb-2 text-2xl font-semibold leading-tight lg:text-3xl'>
              {product?.title}
            </h1>

            {/* Vendor */}
            <p className='px-4 text-sm text-gray-400 line-clamp-1'>
              From
              <button
                onClick={() => props.handleViewVendor()}
                className='ml-2 font-medium text-green-500 bg-green-50 rounded-full px-2'
              >
                {product?.vendor?.name}
              </button>
            </p>
          </section>
        )}

        {/* Buy Bar */}
        {!!product && product.variants && product.variants.length > 0 && (
          <ProductBuyBarWithQuantityButton
            product={product}
            className='sticky top-0 z-40 px-4 bg-white mw-container'
          />
        )}

        <section
          aria-label='Product Details'
          className='px-4 py-2 mw-container'
        >
          <div
            className='pb-4 mb-4 space-y-4 text-base leading-snug text-gray-600 border-b border-gray-200'
            dangerouslySetInnerHTML={{__html: product?.description || ''}}
          />

          {/* Product Specs */}
          <ul className='leading-tight specs'>
            {product && product.tags.length > 0 && (
              <li className='pb-1 mb-1'>
                <label className='text-xs text-gray-500 md:text-base'>
                  Tags
                </label>
                <div className='mt-1 text-sm md:text-base'>
                  {product.tags.split(',').map((tag, index) => {
                    return (
                      <IonBadge
                        className='mr-1 bg-gray-200'
                        color='light'
                        key={index}
                      >
                        {tag}
                      </IonBadge>
                    );
                  })}
                </div>
              </li>
            )}
          </ul>
        </section>

        {product && product?.variants.length > 0 && (
          <IonToolbar className='shadow-lg'>
            <IonSegment value={activeTab}>
              <IonSegmentButton
                onClick={() => setActiveTab('questions')}
                value='questions'
              >
                Q&A
              </IonSegmentButton>
              <IonSegmentButton
                onClick={() => setActiveTab('reviews')}
                value='reviews'
              >
                Reviews
              </IonSegmentButton>
            </IonSegment>
          </IonToolbar>
        )}

        {/* Answers */}
        {product &&
          product?.variants.length > 0 &&
          activeTab === 'questions' && (
            <ProductQuestions
              product={product}
              questions={questions}
              isGuest={props.userState.isGuest}
            />
          )}

        {/* Reviews  */}
        {product && product?.variants.length > 0 && activeTab === 'reviews' && (
          <ProductReviews
            product={product}
            setReviews={setReviews}
            reviews={reviews}
            isGuest={props.userState.isGuest}
          />
        )}

        {!!product &&
          product.variants.length === 0 &&
          product.availableMarkets &&
          product.availableMarkets.length > 0 && (
            <section
              aria-label='Product Details'
              className='px-4 py-4 mw-container'
            >
              <h2 className='text-red-500 font-bold text-xl'>
                This product is not available in your hub, but it is available
                in these hubs:
              </h2>

              <ul className='space-y-2 mt-2'>
                {product.availableMarkets?.map(
                  ({id, name}, index: number) => (
                    <li key={id} className=''>
                      <span className='ml-2 font-medium text-gray-600 px-2'>
                        {name}
                      </span>

                      <button
                        className='ml-2 font-medium text-yellow-500 bg-yellow-50 hover:bg-yellow-100 rounded-full px-2'
                        key={index}
                        onClick={() => props.handleViewHubDetails(id)}
                      >
                        View &rarr;
                      </button>
                    </li>
                  )
                )}
              </ul>
            </section>
          )}
      </main>
    </div>
  );
};

export default ProductHero;
